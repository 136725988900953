import nblifePoster from "../assets/portfolio/demo/poster.jpg";
import nblifeMockupMain from "../assets/portfolio/demo/mockup-main.png";

import unitsOfMassCover from "../assets/portfolio/unitsOfMass/cover.jpg";
import unitsOfMassScoll1 from "../assets/portfolio/unitsOfMass/scroll-1.jpg";
import unitsOfMassScoll2 from "../assets/portfolio/unitsOfMass/scroll-2.jpg";
import unitsOfMassScoll3 from "../assets/portfolio/unitsOfMass/scroll-3.jpg";
import unitsOfMassScoll4 from "../assets/portfolio/unitsOfMass/scroll-4.jpg";
import unitsOfMassScoll5 from "../assets/portfolio/unitsOfMass/scroll-5.jpg";
import unitsOfMassScoll6 from "../assets/portfolio/unitsOfMass/scroll-6.jpg";
import unitsOfMassScoll7 from "../assets/portfolio/unitsOfMass/scroll-7.jpg";
import unitsOfMassScoll8 from "../assets/portfolio/unitsOfMass/scroll-8.jpg";
import unitsOfMassScoll9 from "../assets/portfolio/unitsOfMass/scroll-9.jpg";
import unitsOfMassImg1 from "../assets/portfolio/unitsOfMass/img-1.jpg";
import unitsOfMassImg2 from "../assets/portfolio/unitsOfMass/img-2.jpg";
import unitsOfMassImg3 from "../assets/portfolio/unitsOfMass/img-3.jpg";
import unitsOfMassMockupMain from "../assets/portfolio/unitsOfMass/mockup-main.png";
import unitsOfMassVideo from "../assets/portfolio/unitsOfMass/showcase.mp4";

import riwayMagazineCover from "../assets/portfolio/riwayMagazine/cover.jpg";
import riwayMagazineMockupMain from "../assets/portfolio/riwayMagazine/mockup-main.png";
import riwayMagazineScoll1 from "../assets/portfolio/riwayMagazine/scroll-1.jpg";
import riwayMagazineScoll2 from "../assets/portfolio/riwayMagazine/scroll-2.jpg";
import riwayMagazineScoll3 from "../assets/portfolio/riwayMagazine/scroll-3.jpg";
import riwayMagazineScoll4 from "../assets/portfolio/riwayMagazine/scroll-4.jpg";
import riwayMagazineScoll5 from "../assets/portfolio/riwayMagazine/scroll-5.jpg";
import riwayMagazineScoll6 from "../assets/portfolio/riwayMagazine/scroll-6.jpg";
import riwayMagazineScoll7 from "../assets/portfolio/riwayMagazine/scroll-7.jpg";
import riwayMagazineScoll8 from "../assets/portfolio/riwayMagazine/scroll-8.jpg";
import riwayMagazineScoll9 from "../assets/portfolio/riwayMagazine/scroll-9.jpg";
import riwayMagazineImg1 from "../assets/portfolio/riwayMagazine/img-1.png";
import riwayMagazineImg2 from "../assets/portfolio/riwayMagazine/img-2.png";
import riwayMagazineImg3 from "../assets/portfolio/riwayMagazine/img-3.png";

import fishCoCover from "../assets/portfolio/fishCo/cover.jpg";
import fishCoMockupMain from "../assets/portfolio/fishCo/mockup-main.png";
import fishCoVideo from "../assets/portfolio/fishCo/showcase.mp4";

import ntucFirstCampusCover from "../assets/portfolio/ntucFirstCampus/cover.jpg";
import ntucFirstCampusMockupMain from "../assets/portfolio/ntucFirstCampus/mockup-main.png";
import ntucFirstCampusVideo from "../assets/portfolio/ntucFirstCampus/showcase.mp4";

import benErrandCover from "../assets/portfolio/benErrand/cover.jpg";
import benErrandMockupMain from "../assets/portfolio/benErrand/mockup-main.png";
import benErrandVideo from "../assets/portfolio/benErrand/showcase.mp4";
import benErrandImg1 from "../assets/portfolio/benErrand/img-1.jpg";
import benErrandImg2 from "../assets/portfolio/benErrand/img-2.jpg";
import benErrandImg3 from "../assets/portfolio/benErrand/img-3.jpg";
import benErrandImg4 from "../assets/portfolio/benErrand/img-4.jpg";
import benErrandImg5 from "../assets/portfolio/benErrand/img-5.jpg";
import benErrandImg6 from "../assets/portfolio/benErrand/img-6.jpg";

import MushroomFarmCover from "../assets/portfolio/MushroomFarm/cover.jpg";
import MushroomFarmMockupMain from "../assets/portfolio/MushroomFarm/mockup-main.png";
import MushroomFarmVideo from "../assets/portfolio/MushroomFarm/showcase.mp4";
import MushroomFarmImg1 from "../assets/portfolio/MushroomFarm/img-1.jpg";
import MushroomFarmImg2 from "../assets/portfolio/MushroomFarm/img-2.jpg";
import MushroomFarmImg3 from "../assets/portfolio/MushroomFarm/img-3.jpg";
import MushroomFarmImg4 from "../assets/portfolio/MushroomFarm/img-4.jpg";
import MushroomFarmImg5 from "../assets/portfolio/MushroomFarm/img-5.jpg";

import infographicCover from "../assets/portfolio/infographic/cover.jpg";
import infographicMockupMain from "../assets/portfolio/infographic/mockup-main.png";
import infographicVideo from "../assets/portfolio/infographic/showcase.mp4";
import infographicImg1 from "../assets/portfolio/infographic/img-1.jpg";
import infographicImg2 from "../assets/portfolio/infographic/img-2.jpg";
import infographicImg3 from "../assets/portfolio/infographic/img-3.jpg";

import drawingAppCover from "../assets/portfolio/drawingApp/cover.jpg";
import drawingAppMockupMain from "../assets/portfolio/drawingApp/mockup-main.png";
import drawingAppVideo from "../assets/portfolio/drawingApp/showcase.mp4";
import drawingAppImg1 from "../assets/portfolio/drawingApp/img-1.jpg";
import drawingAppImg2 from "../assets/portfolio/drawingApp/img-2.jpg";
import drawingAppImg3 from "../assets/portfolio/drawingApp/img-3.jpg";
import drawingAppImg4 from "../assets/portfolio/drawingApp/img-4.jpg";

const portfolioData = [
  {
    title: "RIWAY Magazine",
    slug: "riway-magazine",
    framework: "React Native",
    cover: riwayMagazineCover,
    themeColor: "#2f357b",
    url: "https://web.riway.com/download-app/",
    role: "Coding",
    credits: [],
    type: "Mobile App",
    about:
      "This project is a mobile app that let user to read magazines from RIWAY Group. It have some filtering function for region, date and month. Under the reading view, user can use swipe to next page or previous page. There is a preview thumbnail features which user can find it by tapping the reading view. User can scroll to the target thumbnail and tap to jump to the page. If user found out some of the pages are informative, they can also add to bookmark. There also has a download feature to download the magazine to your local device. Another purpose of this app is also allow users which come from different country to use, therefore, multiple languages are also available to switch between. For me, the biggest difficulties in this project is the magazine reading view in portrait and landscape. It need to display 2 pages in single view when the device orientation is landscape. In the end I manage to achieve this by calculate the total pages of the magazine, however it also have to consider the other related features such like bookmark.",
    mockup: riwayMagazineMockupMain,
    scrollImages: [
      {
        row: [riwayMagazineScoll1, riwayMagazineScoll2, riwayMagazineScoll3],
      },
      {
        row: [riwayMagazineScoll4, riwayMagazineScoll5, riwayMagazineScoll6],
      },
      {
        row: [riwayMagazineScoll7, riwayMagazineScoll8, riwayMagazineScoll9],
      },
    ],
    screenshot: [
      {
        title: "Select Magazine",
        file: riwayMagazineImg1,
        orientation: "landscape",
      },
      {
        title: "Read Magazine",
        file: riwayMagazineImg2,
        orientation: "landscape",
      },
      {
        title: "Download and Bookmark",
        file: riwayMagazineImg3,
        orientation: "landscape",
      },
    ],
    showcase: {
      title: "Drag and drop events",
      file: null,
    },
  },
  {
    title: "Ben's Errand",
    slug: "bens-errand",
    framework: "React",
    cover: benErrandCover,
    themeColor: "#d8795f",
    url: "",
    role: "Coding",
    credits: [],
    type: "Interative",
    about:
      "An education purpose related React application, focus on Drag and Drop functionality. User will need to turn on their audio, and select a character to start the journey. After that user need to listen to the audio instruction carefully because it will provide some hints for the location sequence. User can also replay the insturction if they missed any hints from the audio instruction. When user submit the answer, it will have an animation that show the character walk to each of the location. This actually is achieve an amazing animation library call Lottie. I make use of this library to achieve alot of animation sequence.",
    mockup: benErrandMockupMain,
    scrollImages: [],
    screenshot: [
      {
        title: "Start",
        file: benErrandImg1,
        orientation: "landscape",
      },
      {
        title: "Turn on audio",
        file: benErrandImg2,
        orientation: "landscape",
      },
      {
        title: "Select a character",
        file: benErrandImg3,
        orientation: "landscape",
      },
      {
        title: "Listen to the intruction",
        file: benErrandImg4,
        orientation: "landscape",
      },
      {
        title: "Drag and drop",
        file: benErrandImg5,
        orientation: "landscape",
      },
      {
        title: "Result",
        file: benErrandImg6,
        orientation: "landscape",
      },
    ],
    showcase: {
      title: "Little boy walk around animation",
      file: benErrandVideo,
    },
  },
  {
    title: "Units of Mass",
    slug: "units-of-mass",
    framework: "React",
    cover: unitsOfMassCover,
    themeColor: "#d8795f",
    url: "",
    role: "Coding",
    credits: [],
    type: "Interative",
    about:
      "This react application is use for education purpose. It have a drag and drop features that allow user to drag the correct item or drop to the correct area. User will be listen to the instruction first then follow by the action.",
    mockup: unitsOfMassMockupMain,
    scrollImages: [
      {
        row: [unitsOfMassScoll1, unitsOfMassScoll2, unitsOfMassScoll3],
      },
      {
        row: [unitsOfMassScoll4, unitsOfMassScoll5, unitsOfMassScoll6],
      },
      {
        row: [unitsOfMassScoll7, unitsOfMassScoll8, unitsOfMassScoll9],
      },
    ],
    screenshot: [
      {
        title: "Activity 1",
        file: unitsOfMassImg1,
        orientation: "landscape",
      },
      {
        title: "Activity 2",
        file: unitsOfMassImg2,
        orientation: "landscape",
      },
      {
        title: "Activity 3",
        file: unitsOfMassImg3,
        orientation: "landscape",
      },
    ],
    showcase: {
      title: "Drag and drop events",
      file: unitsOfMassVideo,
    },
  },
  {
    title: "Drawing App",
    slug: "drawing-app",
    framework: "React",
    cover: drawingAppCover,
    themeColor: "#d8795f",
    url: "",
    role: "Coding",
    credits: [],
    type: "Interative",
    about:
      "This application is all about creativity. When I was a child, I remember my computer class taught us how to use Microsoft Paint to draw anything you want at a computer. This project bring those memories back. At the homepage, I use animation library Lottie to embellishment the UI. Next, user need to fill up user details - name, class, title of the painting. Next user need to select a paper type, which some of it are having line for guideline. At the artboard, it included all the useful tools such like changing the pencil size, change colour, eraser, undo, clear. After user are completed the artwork, they can also print their artwork by pressing the print button.",
    mockup: drawingAppMockupMain,
    scrollImages: [],
    screenshot: [
      {
        title: "Fill up details",
        file: drawingAppImg1,
        orientation: "landscape",
      },
      {
        title: "Select paper type",
        file: drawingAppImg2,
        orientation: "landscape",
      },
      {
        title: "Start your artwork",
        file: drawingAppImg3,
        orientation: "landscape",
      },
      {
        title: "Print",
        file: drawingAppImg4,
        orientation: "landscape",
      },
    ],
    showcase: {
      title: "Drawing features",
      file: drawingAppVideo,
    },
  },

  {
    title: "Ntuc First Campus",
    slug: "ntuc-first-campus",
    framework: "React",
    cover: ntucFirstCampusCover,
    themeColor: "#d8795f",
    url: "https://www.ntucfirstcampus.com/",
    role: "Coding",
    credits: [],
    type: "Infomative",
    about:
      "This is the first React application that I work for my new company in 2019. What I need to achieve is the complexity of the animation flow. After some research, I able to solve it by using GSAP and Scroll Magic. On the very bottom of the home page, it will show a kid walk through to each of the floor, this is achieve by GSAP timeline. The interesting part is when user are using mobile device to view the section, it become the kid is climbing the ladder up and down based on the scroll direction.",
    mockup: ntucFirstCampusMockupMain,
    scrollImages: [],
    screenshot: [],
    showcase: {
      title: "Little boy walk around animation",
      file: ntucFirstCampusVideo,
    },
  },

  {
    title: "Mushroom Farm",
    slug: "mushroom-farm",
    framework: "React",
    cover: MushroomFarmCover,
    themeColor: "#d8795f",
    url: "",
    role: "Coding",
    credits: [],
    type: "Interative",
    about:
      "A React application for education learning purpose. It include paragraphy sorting, highligh the key term. It also able to show the correct answer if the user not able to answer the question. In the end, it also have a summary to explain the overall of the learning material. Working on this kind of project, I have to very clear about the sequence and organize the state managment for corrent and wrong answer by using Redux.",
    mockup: MushroomFarmMockupMain,
    scrollImages: [],
    screenshot: [
      {
        title: "Start",
        file: MushroomFarmImg1,
        orientation: "landscape",
      },
      {
        title: "Sorting answer",
        file: MushroomFarmImg2,
        orientation: "landscape",
      },
      {
        title: "Highlight answer",
        file: MushroomFarmImg3,
        orientation: "landscape",
      },
      {
        title: "Show answer",
        file: MushroomFarmImg4,
        orientation: "landscape",
      },
      {
        title: "Summary",
        file: MushroomFarmImg5,
        orientation: "landscape",
      },
    ],
    showcase: {
      title: "Drag to sort",
      file: MushroomFarmVideo,
    },
  },

  {
    title: "Fish & Co",
    slug: "fish-co",
    framework: "Wordpress",
    cover: fishCoCover,
    themeColor: "#d8795f",
    url: "https://www.fish-co.com/",
    role: "Coding",
    credits: [],
    type: "CMS Website",
    about:
      "A content management system develop by using Wordpress. I have the chances to try the scroll animation and SVG path animation in this project. In the home page, there is a hook will animate base on the scroll length. Under menu page, it also have the SVG path animation base on the scroll length, don't forget to look at the dishes as well, it will rotate too!",
    mockup: fishCoMockupMain,
    scrollImages: [],
    screenshot: [],
    showcase: {
      title: "Svg animation",
      file: fishCoVideo,
    },
  },
  {
    title: "Interative Infographic",
    slug: "interative-infographic",
    framework: "React",
    cover: infographicCover,
    themeColor: "#d8795f",
    url: "",
    role: "Coding",
    credits: [],
    type: "Interative",
    about:
      "A infographic site that give the importance of the arts by some of interactive action. One of the interesting part for this site in at one of the secion - Can You Imagine Living In A World Without... . User need to click each of the button to fade out the decation at the background. Once it all fade out, it show an empty living room.",
    mockup: infographicMockupMain,
    scrollImages: [],
    screenshot: [
      {
        title: "Opening",
        file: infographicImg1,
        orientation: "landscape",
      },
      {
        title: "Interactive event before",
        file: infographicImg2,
        orientation: "landscape",
      },
      {
        title: "Interactive event after",
        file: infographicImg3,
        orientation: "landscape",
      },
    ],
    showcase: {
      title: "Drag to sort",
      file: infographicVideo,
    },
  },
];

export { portfolioData };
