const experienceData = [
  {
    duration: "2021 - Present",
    company: "Senior Frontend Developer",
    position: "Ernst & Young",
  },
  {
    duration: "2020 - 2021",
    company: "I Concept Digital",
    position: "Senior Web Programmer",
  },
  {
    duration: "2019 - 2020",
    company: "I Concept Digital",
    position: "Junior Web Programmer",
  },
  {
    duration: "2019 - 2019",
    company: "GM Design Studio",
    position: "Project Executive cum Web Developer",
  },
  {
    duration: "2016 - 2019",
    company: "GM Design Studio",
    position: "Freelance Web Programmer",
  },
];

export { experienceData };
