import React, { useEffect, useRef, useState } from "react";
import "./works.scss";
import { portfolioData } from "../../data/portfolioData";
import { MenuItem } from "../../components";
import { gsap } from "gsap/all";

const Works = () => {
  const menuRef = useRef(null);
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    let menuTextinner = [
      ...menuRef.current.querySelectorAll(".menu__item-textinner"),
    ];
    if (menuTextinner) {
      gsap
        .timeline({
          onComplete: () => {
            setAnimationComplete(true);
          },
        })
        .set(menuTextinner, {
          y: "110%",
        })
        .to(menuTextinner, {
          ease: "Power2.easeOut",
          stagger: 0.04,
          delay: 0.2,
          duration: menuTextinner.length * 0.05,
          y: "0%",
        });
    }
  }, []);

  return (
    <div className="works">
      <div className={`menu ${animationComplete && "complete"}`} ref={menuRef}>
        {portfolioData.map((portfolio, index) => (
          <MenuItem
            key={index}
            position={index}
            data={portfolio}
            parent={menuRef}
          />
        ))}
      </div>
    </div>
  );
};

export default Works;
