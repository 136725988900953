// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

const clamp = (num, min, max) => (num <= min ? min : num >= max ? max : num);

const distance = (x1, x2, y1, y2) => {
  var a = x1 - x2;
  var b = y1 - y2;
  return Math.hypot(a, b);
};

const calcWinsize = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const lineEq = (y2, y1, x2, x1, currentVal) => {
  // y = mx + b
  var m = (y2 - y1) / (x2 - x1),
    b = y1 - m * x1;
  return m * currentVal + b;
};

// Gets the mouse position
const getMousePos = (e) => {
  let posx = 0;
  let posy = 0;
  if (!e) e = window.event;
  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    posy =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  return { x: posx, y: posy };
};

const animatableProperties = {
  tx: { previous: 0, current: 0, amt: 0.08 },
  // translationY
  ty: { previous: 0, current: 0, amt: 0.08 },
  // Rotation angle
  rotation: { previous: 0, current: 0, amt: 0.08 },
  // CSS filter (brightness) value
  brightness: { previous: 1, current: 1, amt: 0.08 },
};

export {
  map,
  lerp,
  clamp,
  distance,
  lineEq,
  calcWinsize,
  getRandomNumber,
  getMousePos,
  animatableProperties,
};
