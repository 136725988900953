import React, { useEffect, useRef, useState } from "react";
import "./loading.scss";
import { gsap, CSSRulePlugin } from "gsap/all";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

gsap.registerPlugin(CSSRulePlugin);
Splitting();

const Loading = ({ setLoading }) => {
  const [progress, setProgress] = useState(true);

  let loadingRef = useRef(null);
  let reveal1 = useRef(null);
  let reveal2 = useRef(null);
  let title = useRef(null);
  let barRef = useRef(null);

  useEffect(() => {
    const subTitleSplit = Splitting({
      by: "chars",
    });
    let titleColor = CSSRulePlugin.getRule(
      ".loading .loading__title h1:before"
    );
    gsap
      .timeline({
        onComplete: () => {
          setProgress(false);
        },
      })

      .set(subTitleSplit[0].chars, {
        y: "100%",
      })
      .set(title.current, {
        opacity: 0,
      })
      .set(titleColor, {
        width: "0%",
      })
      .set(barRef.current, {
        width: "0%",
      })
      .to(title.current, {
        ease: "Power2.easeOut",
        opacity: 1,
        duration: 1,
        delay: 0.6,
      })
      .to(subTitleSplit[0].chars, {
        ease: "Power2.easeOut",
        y: "0%",
        stagger: 0.02,
        duration: 0.4,
      })
      .to(titleColor, {
        width: "100%",
        ease: "slow",
        duration: 1.5,
      })
      .to(barRef.current, {
        width: "100%",
        ease: "slow",
        duration: 1.5,
        delay: -1.5,
      });
  }, []);
  useEffect(() => {
    if (!progress) {
      gsap
        .timeline({
          onComplete: () => {
            setLoading(false);
          },
        })
        .to([reveal2.current, reveal1.current], {
          duration: 0.8,
          delay: 0.3,
          height: 0,
          ease: "power3.inOut",
          stagger: {
            amount: 0.07,
          },
        })
        .to(loadingRef.current, {
          duration: 1,
          css: { display: "none" },
        });
    }
  }, [progress]);

  return (
    <div className="loading" ref={loadingRef}>
      <div ref={reveal1} className="loading__backgroundLayer"></div>
      <div className="loading__layer" ref={reveal2}>
        <div className="loading__bar" ref={barRef}></div>
        <div className="loading__title">
          <h1
            ref={title}
            className="loading__title-main"
            data-text="DENNIS LOH"
          >
            Dennis Loh
          </h1>
          <span className="loading__title-sub" data-splitting>
            Software Engineer
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
