import React from "react";
import "./about.scss";
import { experienceData } from "../../data/experienceData";
import Myself from "../../assets/myself.jpg";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/data.json";

const About = () => {
  return (
    <div className="about">
      <div className="about__hero">
        <img src={Myself} alt="myself" />
        <span className="quote">
          “The power of imagination makes us infinite.”
        </span>
      </div>
      <div className="about__content">
        <div className="about__me">
          <h1>About Me</h1>
          <p>
            Hello, I'm Dennis, a Software Engineer based in Malaysia and
            Singapore. Professionally connected with front end development
            industry and specializing in React, React Native and Wordpress
            application. I'm ALWAYS trying to grow and learn new things.
            Exploring awwwards.com is one of my activity which I able to explore
            the cool web design and animation.
          </p>
        </div>
        <div className="about__other">
          <div className="about__other-experience">
            <h1>Experience</h1>
            <div className="about__experience">
              {experienceData.map((experience, index) => (
                <div className="experience" key={index}>
                  <div className="experience__duration">
                    <span>{experience.duration}</span>
                  </div>
                  <div className="experience__company">
                    <span>{experience.company}</span>
                  </div>
                  <div className="experience__position">
                    <span>{experience.position}</span>
                  </div>
                </div>
              ))}
            </div>
            <a
              href={process.env.PUBLIC_URL + "/resume/Dennis_Loh_Resume.pdf"}
              target="_blank"
              className="about__resume"
            >
              Download Resume
            </a>
          </div>
          <div className="social">
            <h1>Socials</h1>
            <a href="https://www.linkedin.com/in/dennis-loh/">Linkedln</a>
            <a href="https://www.instagram.com/dennisloh95/">Instagram</a>
            <a href="mailto:dennisloh95@gmail.com">Email</a>
          </div>
        </div>
        <div className="about__thankyou">
          <span>Thanks for visiting!</span>
          <div className="lottie-wrapper">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData.default,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={"100%"}
              width={"100%"}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
