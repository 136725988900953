import React, { useRef, useEffect } from "react";
import "./portfolio.scss";
import { portfolioData } from "../../data/portfolioData";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowRoundForward } from "react-icons/io";
import { gsap, ScrollTrigger, CSSRulePlugin } from "gsap/all";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import AnchorLink from "react-anchor-link-smooth-scroll";

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);
Splitting();

const Portfolio = (props) => {
  const {
    title,
    url,
    credits,
    role,
    type,
    cover,
    framework,
    about,
    scrollImages,
    mockup,
    screenshot,
    showcase,
    themeColor,
  } = props.data;

  const tilesRef = useRef(null);
  const mockupRef = useRef(null);
  const scrolltextRef = useRef(null);
  const splitRef = useRef(null);
  const coverRef = useRef(null);
  const exploreRef = useRef(null);

  useEffect(() => {
    const titleSplit = Splitting({
      by: "chars",
    });
    let imageReveal = CSSRulePlugin.getRule(".portfolio__cover:after");

    gsap
      .timeline({
        onComplete: () => console.log("animation complete"),
      })
      .set(splitRef.current, {
        opacity: 0,
      })
      .set(exploreRef.current, {
        opacity: 0,
      })
      .set(imageReveal, {
        width: "100%",
        // backgroundColor: "white",
      })
      .to(imageReveal, {
        width: "0%",
        duration: 1.4,
        ease: "Power2.easeInOut",
      })
      .from(coverRef.current, {
        duration: 1.4,
        scale: 1.3,
        ease: "Power2.easeInOut",
        delay: -1.6,
      })
      .set(splitRef.current, {
        opacity: 1,
      })
      .set(titleSplit[0].chars, {
        y: "100%",
        delay: -1,
      })
      .to(titleSplit[0].chars, {
        ease: "Power2.easeOut",
        y: "0%",
        stagger: 0.014,
        duration: 0.8,
      })
      .to(exploreRef.current, {
        opacity: 1,
        ease: "Power2.easeOut",
        duration: 1,
      });

    if (tilesRef.current) {
      gsap.to(tilesRef.current.querySelectorAll(".tiles__line.forward"), {
        scrollTrigger: {
          trigger: tilesRef.current,
          scrub: true,
          start: "-=600",
        },
        x: "-20%",
      });
      gsap.to(tilesRef.current.querySelectorAll(".tiles__line.reverse"), {
        scrollTrigger: {
          trigger: tilesRef.current,
          scrub: true,
          start: "-=600",
        },
        x: "20%",
      });
    }

    if (scrolltextRef.current) {
      gsap.to(scrolltextRef.current, {
        scrollTrigger: {
          trigger: scrolltextRef.current,
          scrub: true,
          start: "-=600",
          // markers: true,
        },
        x: "-50%",
      });
    }
  }, []);

  const getNextProject = (currentIndex) => {
    let tempNextIndex = currentIndex + 1;
    tempNextIndex = tempNextIndex % portfolioData.length;
    return portfolioData[tempNextIndex];
  };

  return (
    <div className="portfolio">
      <div className="portfolio__hero">
        <h1 className="portfolio__title" ref={splitRef} data-splitting>
          {title}
        </h1>
        <div className="portfolio__cover">
          <img src={cover} alt="cover" ref={coverRef} />
        </div>
        <div className="portfolio__explore" ref={exploreRef}>
          <AnchorLink href="#about">
            <h3>EXPLORE</h3>
            <IoIosArrowDown />
          </AnchorLink>
        </div>
      </div>
      <div className="portfolio__content" id="about">
        <div className="portfolio__about">
          <div className="portfolio__about__title">
            <h3>{title}</h3>
            <h1>About this Project</h1>
          </div>
          <div className="portfolio__about__container">
            <div className="portfolio__about__container__content">
              <p>{about}</p>
              {url && (
                <a href={url} target="_blank">
                  <h4 className="portfolio__about__container__content-visit">
                    Visit the Project
                    <IoIosArrowRoundForward size={25} />
                  </h4>
                </a>
              )}
            </div>
            <div className="portfolio__about__container__info">
              {framework && (
                <div>
                  <h3>Create by</h3>
                  <span>{framework}</span>
                </div>
              )}
              {role && (
                <div>
                  <h3>Role</h3>
                  <span>{role}</span>
                </div>
              )}
              {type && (
                <div>
                  <h3>Type</h3>
                  <span>{type}</span>
                </div>
              )}
              {credits.length > 0 && (
                <div>
                  <h3>Credits</h3>
                  {credits.map((credit, index) => (
                    <span>{credit}</span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="portfolio__graphic">
          <div className="portfolio__graphic__mockup">
            <img src={mockup} alt="mockup" ref={mockupRef} />
          </div>
          {scrollImages.length === 3 ? (
            <div className="portfolio__tiles">
              <div className="tiles" ref={tilesRef}>
                <div className="tiles__wrap">
                  {scrollImages.map((data, index) => (
                    <div
                      className={`tiles__line ${
                        index % 2 ? "reverse" : "forward"
                      } `}
                      key={index}
                    >
                      {data.row.map((image, imageIndex) => (
                        <div
                          className="tiles__line-img"
                          key={imageIndex}
                          style={{ backgroundImage: `url(${image})` }}
                        ></div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          <div className="portfolio__graphic__screenshot">
            {screenshot.map((shot, index) => (
              <div
                className="portfolio__graphic__screenshot__content"
                key={index}
              >
                <span>{index < 10 ? `0${index + 1}` : index}</span>
                <h1>{shot.title}</h1>
                <img src={shot.file} alt="screenshot" />
              </div>
            ))}
          </div>
          {showcase.file ? (
            <>
              <div className="portfolio__graphic__scrollText">
                <div
                  className="portfolio__graphic__scrollText-inner"
                  ref={scrolltextRef}
                >
                  {[...Array(10)].map((_, i) => (
                    <span key={i}>showcase</span>
                  ))}
                </div>
              </div>
              <div className="portfolio__graphic__showcase">
                <video
                  width="100%"
                  playsInline
                  muted
                  autoPlay
                  loop
                  src={showcase.file}
                />
                <p>{showcase.title}</p>
              </div>
            </>
          ) : null}
        </div>

        {/* <div className="portfolio__next">
      
        </div> */}
      </div>
      <div className="portfolio__footer">
        <div className="index">
          <span>{`${props.currentIndex + 1} / ${portfolioData.length}`}</span>
        </div>
        <Link
          className="next"
          to={`/works/${getNextProject(props.currentIndex).slug}`}
        >
          <span>Next</span>
        </Link>
      </div>
    </div>
  );
};

export default Portfolio;
