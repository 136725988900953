import React, { useEffect, useRef } from "react";
import "./home.scss";
import { portfolioData } from "../../data/portfolioData";
import { gsap } from "gsap";
import {
  map,
  lerp,
  calcWinsize,
  getRandomNumber,
  getMousePos,
  distance,
} from "../../constants/utils";
import { Link } from "react-router-dom";

const Home = () => {
  let winsize = calcWinsize();
  let mousepos = { x: winsize.width / 2, y: winsize.height / 2 };
  let translationVals = { tx: 0, ty: 0 };
  let xstart = getRandomNumber(10, 30);
  let ystart = getRandomNumber(10, 25);
  let requestId;

  let translationValsAction = { tx: 0, ty: 0 };

  const contentSlideRef = useRef(null);
  const actionRef = useRef(null);
  let slideItemsRef = useRef(null);
  let slideTextRef = useRef(null);

  const addEventListener = () => {
    window.addEventListener("resize", (e) => (winsize = e));
    window.addEventListener("mousemove", (ev) => (mousepos = getMousePos(ev)));
  };
  const removeEventListeners = () => {
    window.removeEventListener("resize", (e) => (winsize = e));
    window.removeEventListener(
      "mousemove",
      (ev) => (mousepos = getMousePos(ev))
    );
  };

  const start = () => {
    if (!requestId) {
      requestId = requestAnimationFrame(() => render());
    }
  };
  const stop = () => {
    if (requestId) {
      window.cancelAnimationFrame(requestId);
      requestId = undefined;
    }
  };

  const render = () => {
    requestId = undefined;
    translationVals.tx = lerp(
      translationVals.tx,
      map(mousepos.x, 0, winsize.width, xstart, -xstart),
      0.07
    );
    translationVals.ty = lerp(
      translationVals.ty,
      map(mousepos.y, 0, winsize.height, ystart, -ystart),
      0.07
    );

    gsap.set(slideItemsRef.current, {
      x: translationVals.tx,
      y: translationVals.ty,
    });

    if (actionRef.current) {
      let actionRect = actionRef.current.getBoundingClientRect();
      const d = distance(
        mousepos.x,
        actionRect.left + actionRect.width / 2,
        mousepos.y,
        actionRect.top + actionRect.height / 2
      );
      const x =
        d < actionRect.width * 0.9
          ? mousepos.x - (actionRect.left + actionRect.width / 2)
          : 0;
      const y =
        d < actionRect.height * 0.9
          ? mousepos.y - (actionRect.top + actionRect.height / 2)
          : 0;

      translationValsAction.tx = lerp(translationValsAction.tx, x * 0.6, 0.17);
      translationValsAction.ty = lerp(translationValsAction.ty, y * 0.6, 0.17);
      gsap.set(actionRef.current, {
        x: translationValsAction.tx,
        y: translationValsAction.ty,
      });
      gsap.set(actionRef.current.children[0], {
        x: -translationValsAction.tx * 0.12,
        y: -translationValsAction.ty * 0.12,
      });
    }

    start();
  };

  useEffect(() => {
    addEventListener();
    let header = document.body.querySelector(".header");
    slideItemsRef.current = [...contentSlideRef.current.childNodes].slice(0, 8);
    slideTextRef.current = [...contentSlideRef.current.childNodes][8];

    if (contentSlideRef.current) {
      slideItemsRef.current.map((item) =>
        gsap.set(item, {
          x:
            winsize.width / 2 -
            item.getBoundingClientRect().left -
            item.getBoundingClientRect().width / 2,
          y:
            winsize.height / 2 -
            item.getBoundingClientRect().top -
            item.getBoundingClientRect().height / 2,
          scale: 1.2,
          rotation: getRandomNumber(-10, 10),
          opacity: 0,
        })
      );

      gsap
        .timeline({
          onComplete: () => {
            render();
          },
        })
        .set(slideTextRef.current, {
          y: "-50%",
          opacity: 0,
        })
        .set(slideItemsRef.current, {
          opacity: 1,
          stagger: 0.2,
          delay: 0.3,
        })
        .to(slideItemsRef.current, {
          duration: 0.9,
          ease: "Expo.easeOut",
          x: 0,
          y: 0,
          rotation: 0,
          scale: 1,
          stagger: 0.03,
          delay: 0.3,
          opacity: 0.6,
        })
        .to(slideTextRef.current, {
          ease: "Expo.easeOut",
          duration: 0.9,
          y: 0,
          opacity: 1,
          stagger: 0.2,
          delay: -1,
        });
    }

    return () => {
      removeEventListeners();
      stop();
      //   gsap.killTweensOf(header);
      gsap.killTweensOf(slideTextRef);
      gsap.killTweensOf(slideTextRef);
      gsap.killTweensOf(contentSlideRef);
      gsap.killTweensOf(actionRef.current);
    };
  }, []);

  return (
    <div className="home">
      <div className="content">
        <div className="content__slide" ref={contentSlideRef}>
          {portfolioData.slice(0, 8).map((data, index) => (
            <div
              key={index}
              className="content__slide-item"
              style={{ backgroundImage: `url(${data.cover})` }}
            ></div>
          ))}
          <div className="content__slide-text">
            <div className="content-text">
              <span className="sub">Welcome to my</span>
              <h1 className="title">Portfolio</h1>
              <Link
                to="/works"
                className="text content__action"
                ref={actionRef}
              >
                <span className="content__action-text">Enter</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
