import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { map, lerp, clamp, animatableProperties } from "../../constants/utils";
import { Link } from "react-router-dom";

const MenuItem = (props) => {
  let firstRAFCycle = true;
  let bounds;

  let menuItem = useRef(null);
  let textInner = useRef(null);
  let reveal = useRef(null);
  let revealInner = useRef(null);
  let revealImage = useRef(null);
  let requestId = useRef();

  let mousepos = { x: 0, y: 0 };
  let mousePosCache = mousepos;
  let direction = {
    x: mousePosCache.x - mousepos.x,
    y: mousePosCache.y - mousepos.y,
  };

  const calcBounds = () => {
    if (menuItem.current) {
      bounds = {
        el: menuItem.current.getBoundingClientRect(),
        reveal: reveal.current.getBoundingClientRect(),
      };
    }
  };

  const addEventListener = () => {
    document.addEventListener("mousemove", onMouseMove);
    menuItem.current.addEventListener("mouseenter", onMouseEnter);
    menuItem.current.addEventListener("mouseleave", onMouseLeave);
  };
  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    gsap.killTweensOf(revealInner.current);
    gsap.killTweensOf(revealImage.current);
    gsap.killTweensOf(menuItem.current);
    gsap.killTweensOf(document.body);
  };

  useEffect(() => {
    addEventListener();
    return () => removeEventListeners();
  }, []);

  const onMouseMove = (e) => {
    mousepos = { x: e.clientX, y: e.clientY };
  };

  const onMouseEnter = (ev) => {
    showImage();
    firstRAFCycle = true;
    loopRender();
  };

  const onMouseLeave = () => {
    stopRendering();
    hideImage();
  };

  const showImage = () => {
    gsap.killTweensOf(revealInner.current);
    gsap.killTweensOf(revealImage.current);
    gsap
      .timeline({
        onStart: () => {
          reveal.current.style.opacity = 1;
          gsap.set(menuItem.current, { zIndex: props.data.cover.length });
        },
      })
      .to(revealInner.current, {
        duration: 0.2,
        ease: "Sine.easeOut",
        startAt: { x: direction.x < 0 ? "-100%" : "100%" },
        x: "0%",
      })
      .to(
        revealImage.current,
        {
          duration: 0.2,
          ease: "Sine.easeOut",
          startAt: { x: direction.x < 0 ? "100%" : "-100%" },
          x: "0%",
        },
        0
      );
  };

  const hideImage = () => {
    gsap.killTweensOf(revealInner.current);
    gsap.killTweensOf(revealImage.current);

    gsap
      .timeline({
        onStart: () => {
          gsap.set(menuItem.current, { zIndex: 1 });
        },
        onComplete: () => {
          gsap.set(reveal.current, { opacity: 0 });
        },
      })
      .to(revealInner.current, {
        duration: 0.2,
        ease: "Sine.easeOut",
        x: direction.x < 0 ? "100%" : "-100%",
      })
      .to(
        revealImage.current,
        {
          duration: 0.2,
          ease: "Sine.easeOut",
          x: direction.x < 0 ? "-100%" : "100%",
        },
        0
      );
  };
  const loopRender = () => {
    if (requestId) {
      requestId.current = requestAnimationFrame(() => render());
    }
  };
  const stopRendering = () => {
    if (requestId.current) {
      window.cancelAnimationFrame(requestId.current);
      requestId.current = undefined;
    }
  };

  const render = () => {
    if (firstRAFCycle) {
      calcBounds();
    }

    const mouseDistanceX = clamp(
      Math.abs(mousePosCache.x - mousepos.x),
      0,
      100
    );

    direction = {
      x: mousePosCache.x - mousepos.x,
      y: mousePosCache.y - mousepos.y,
    };
    mousePosCache = { x: mousepos.x, y: mousepos.y };

    animatableProperties.tx.current =
      Math.abs(mousepos.x - bounds.el.left) - bounds.reveal.width / 2;
    animatableProperties.ty.current =
      Math.abs(mousepos.y - bounds.el.top) - bounds.reveal.height / 2;

    animatableProperties.rotation.current = firstRAFCycle
      ? 0
      : map(mouseDistanceX, 0, 100, 0, direction.x < 0 ? 60 : -60);
    animatableProperties.brightness.current = firstRAFCycle
      ? 1
      : map(mouseDistanceX, 0, 100, 1, 4);

    animatableProperties.tx.previous = firstRAFCycle
      ? animatableProperties.tx.current
      : lerp(
          animatableProperties.tx.previous,
          animatableProperties.tx.current,
          animatableProperties.tx.amt
        );
    animatableProperties.ty.previous = firstRAFCycle
      ? animatableProperties.ty.current
      : lerp(
          animatableProperties.ty.previous,
          animatableProperties.ty.current,
          animatableProperties.ty.amt
        );
    animatableProperties.rotation.previous = firstRAFCycle
      ? animatableProperties.rotation.current
      : lerp(
          animatableProperties.rotation.previous,
          animatableProperties.rotation.current,
          animatableProperties.rotation.amt
        );
    animatableProperties.brightness.previous = firstRAFCycle
      ? animatableProperties.brightness.current
      : lerp(
          animatableProperties.brightness.previous,
          animatableProperties.brightness.current,
          animatableProperties.brightness.amt
        );

    if (reveal.current) {
      gsap.set(reveal.current, {
        x: animatableProperties.tx.previous,
        y: animatableProperties.ty.previous,
        // rotation: animatableProperties.rotation.previous,
        // filter: `brightness(${animatableProperties.brightness.previous})`,
      });
    }

    // loop
    firstRAFCycle = false;
    loopRender();
  };

  return (
    <Link
      to={{ pathname: `/works/${props.data.slug}` }}
      className="menu__item"
      data-img={props.data.cover}
      ref={menuItem}
    >
      <div className="menu__item-text">
        <span className="menu__item-textinner" ref={textInner}>
          {props.data.title}
        </span>
      </div>
      <span className="menu__item-sub">{props.data.framework}</span>
      <div className="hover-reveal" ref={reveal}>
        <div className="hover-reveal__inner" ref={revealInner}>
          <div
            className="hover-reveal__img"
            style={{ backgroundImage: `url(${props.data.cover})` }}
            ref={revealImage}
          ></div>
        </div>
      </div>
    </Link>
  );
};

export default MenuItem;
