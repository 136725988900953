import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "./style.scss";

import { Header, Loading } from "./components";
import { Works, About, Portfolio, NotFound, Home } from "./pages";

import { portfolioData } from "./data/portfolioData";
const routes = [
  {
    name: "Home",
    slug: "/",
    exact: true,
    component: <Home />,
  },
  {
    name: "Works",
    slug: "/works",
    exact: true,
    component: <Works />,
  },
  {
    name: "About",
    slug: "/about",
    exact: true,
    component: <About />,
  },
  ...portfolioData.map((data, index) => ({
    name: data.title,
    slug: `/works/${data.slug}`,
    exact: true,
    component: <Portfolio data={data} currentIndex={index} />,
  })),
  {
    name: "Not Found",
    slug: "*",
    exact: false,
    component: <NotFound />,
  },
];

const App = () => {
  const [loading, setLoading] = useState(false);

  const scrollContainer = useRef(null);

  return (
    <main ref={scrollContainer}>
      <Router>
        <Header />
        {loading && <Loading setLoading={setLoading} />}
        <Route
          render={({ location }) => (
            <AnimatePresence initial={false} exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                {/* <Route exact path="/" render={() => <Home />} /> */}
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={route.slug}
                    render={() => (
                      <motion.div
                        onAnimationComplete={() => window.scrollTo(0, 0)}
                        transition={{ duration: 0.3 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        {route.component}
                      </motion.div>
                    )}
                  />
                ))}
              </Switch>
            </AnimatePresence>
          )}
        />
      </Router>
    </main>
  );
};

export default App;
