import React from "react";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="notfound">
      <h1>OH NO! something broke.</h1>
      <div>
        <h3>Error 404 - page not found.</h3>
      </div>
    </div>
  );
};

export default NotFound;
